<!-- 是否派单 -->
<template>
  <div>
    <el-dialog
        width="50%"
        center
        :visible="visible"
        :close-on-click-modal="false"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'是否派单':'是否派单'"
        @update:visible="updateVisible">

      <div slot="title">
        <div style="display: flex;align-items: center;">
          <el-button type="primary" size="small" @click="openScript()">跟进话术</el-button>
          <div class="tianjiagenjin">
            {{isUpdate?'是否派单':'是否派单'}}
          </div>
        </div>
      </div>

      <el-form
          ref="form"
          :model="form"
          label-width="140px">

        <div v-if="isUpdate === false">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/renli/biaozhi.png" alt="" style="width: 19px;height: 19px;margin-right: 10px;">
              <span style="font-size: 20px;font-weight: 500;color: #3f4155;margin-right: 10px;">该救援订单需要人工派单,是否指定接单人?</span>
              <el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;" @click="openEdit()" v-auths="[`${$config.uniquePrefix}orderModule:list:acceptedit`]">
                <i class="el-icon-bianji" />
                <span style="vertical-align: middle">编辑</span>
              </el-button>
            </div>
          </div>

          <el-row :gutter="15" style="margin-top: 15px;">
            <el-col :span="24">
              <el-form-item label="订单号/案件号：" style="margin-bottom: 0!important;">
                <span style="margin-right: 10px;">{{form.order_id}}</span>
                <span>{{form.case_number}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--<el-form-item label="客户名称：" prop=""  style="margin-bottom: 0!important;">-->
              <!--  <span>柳新吉</span>-->
              <!--</el-form-item>-->
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="下单时间："  style="margin-bottom: 0!important;">
                <span>{{form.order_time}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="客户电话：" prop=""  style="margin-bottom: 0!important;">
                <div style="display: flex;align-items: center;" v-if="form.buyer_phone != '' && form.buyer_phone != undefined">
                  <span>{{form.buyer_phone}}</span>
                  <img src="@/assets/images/home/dianhua.png" alt="" style="width: 18px;height: 18px;margin-left: 10px;" @click="tel(form.owners_phone,'其他')">
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="车主姓名："  style="margin-bottom: 0!important;">
                <span>{{form.owners}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车主电话：" prop=""  style="margin-bottom: 0!important;">
                <div style="display: flex;align-items: center;" v-if="form.owners_phone != ''">
                  <span>{{form.owners_phone}}</span>
                  <img src="@/assets/images/home/dianhua.png" alt="" style="width: 18px;height: 18px;margin-left: 10px;" @click="tel(form.owners_phone,'客户')">
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="救援类型："  style="margin-bottom: 0!important;">
                <span>{{form.items_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="故障车牌：" prop=""  style="margin-bottom: 0!important;">
                <span>{{form.license_plate}}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="24">
              <el-form-item label="救援地：">
                <div v-if="dirType == 'double'">
                  <!--<span>{{form.starting_point}}</span>-->
                  <span>{{form.destination}}</span>
                </div>
                <div v-else>
                  <span>{{form.starting_point}}</span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div v-else>
          <!--<el-row :gutter="15">-->
          <!--  <el-col :span="12">-->
          <!--    -->
          <!--  </el-col>-->
          <!--  <el-col :span="12">-->
          <!--    -->
          <!--  </el-col>-->
          <!--</el-row>-->
          <el-form-item label="车主姓名：">
            <span>{{form.owners}}</span>
          </el-form-item>
          <el-form-item label="车主电话：" prop="">
            <!--<div style="display: flex;align-items: center;">-->
            <!--  <span>{{form.owners_phone}}</span>-->
            <!--  <img src="@/assets/images/home/dianhua.png" alt="" style="width: 18px;height: 18px;margin-left: 10px;" @click="tel(form.owners_phone,'客户')">-->
            <!--</div>-->
            <el-input
                placeholder="请输入"
                v-model="form.owners_phone"
                clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="服务项目">
            <el-select
                @change="selectServicesAvailable"
              clearable
              v-model="form.rescue_type"
              placeholder="请选择"
              class="ele-fluid">
              <el-option v-for="(item) in ServicesAvailable" :label="item.name" :value="item.items"/>
            </el-select>
          </el-form-item>
          <el-form-item label="救援类型">
            <el-select
                clearable
                v-model="form.is_accident"
                placeholder="请选择"
                class="ele-fluid">
              <el-option
                v-for="(item) in dict.type.rescue_type"
                :label="item.label"
                :value="parseInt(item.value)"
              />
            </el-select>
          </el-form-item>
          <div v-if="dirType == 'double'">
            <el-form-item label="救援地起点:">
              <span style="margin-right: 10px;">{{form.starting_point}}</span>
              <el-button @click="opensiteselection(0)">点击选择</el-button>
            </el-form-item>
            <el-form-item label="救援地终点:">
              <span style="margin-right: 10px;">{{form.destination}}</span>
              <el-button @click="opensiteselection(1)">点击选择</el-button>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="救援地终点:">
              <span style="margin-right: 10px;">{{form.destination}}</span>
              <el-button @click="opensiteselection(1)">点击选择</el-button>
            </el-form-item>
          </div>
          <el-form-item label="现场照片">
            <el-upload
                :class="{hide_box: upload_btn}"
                :limit="3"
                :action="action"
                :headers="headers"
                :on-success="successUpload"
                :file-list="fileList"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
          <!--<el-form-item label="订单数量上限">-->
          <!--  <el-input-->
          <!--      :maxlength="20"-->
          <!--      v-model="form.name"-->
          <!--      placeholder="请输入数量"-->
          <!--      clearable/>-->
          <!--</el-form-item>-->
        </div>
      </el-form>

      <div slot="footer">
        <el-button
            @click="quxiao()">取消
        </el-button>
        <el-button
          v-if="isUpdate == false"
          style="margin-left: 20px;"
          type="primary"
          v-auths="[`${$config.uniquePrefix}orderModule:list:accept`]"
          @click="submit">提交
        </el-button>
        <el-button
            v-if="isUpdate == false"
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            v-auths="[`${$config.uniquePrefix}orderModule:list:acceptsend`]"
            @click="save">确认去派单
        </el-button>
        <el-button
            v-else
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            v-auths="[`${$config.uniquePrefix}orderModule:list:acceptedit`]"
            @click="confirm">确认修改
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      center
      v-if="showlocation == true"
      title="选择地址"
      :visible.sync="showlocation"
      width="40%"
      :before-close="handleClose">
      <div>
        <!--<iframe id="mapPage" width="100%" height="700px;" frameborder="0" v-bind:src="keyUrl"></iframe>-->
        <!--自定义高德选点组件-->
        <AMapLoadersiteselection v-if="showAMapLoadersiteselection === true" :data="origin" @location="getlocation"/>
      </div>
      <div slot="footer">
        <!--<el-button-->
        <!--  @click="cancel">取消-->
        <!--</el-button>-->
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import settingConfig from '@/config/config'

//服务项目接口
import {getget_services_available} from '@/api/yunli'
//拖动排序
import draggable from 'vuedraggable'
import {
  delay_joinread,
  Editdelay_join,
  get_services_config,
  getread,
  getrescue_type,
  Saveaccept
} from "@/api/orderModule";
import {get_tel_no,} from '@/api/phone'

//引入自定义高德地图选点组件
import AMapLoadersiteselection from '@/components/AMapLoadersiteselection/index.vue'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    //字典数据
    dict: Object
  },
  components: {
    draggable,
    AMapLoadersiteselection
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn:false,
      fileList:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/order',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //服务项目
      ServicesAvailable:[],

      //位置
      showlocation:false,
      //起点为0 终点为1
      origin:0,
      // 腾讯地图选址组件url
      keyUrl:'',
      showAMapLoadersiteselection:false,

      //单双地址 single为单地址 double为双地址
      dirType:'',

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        //救援地点的经度纬度 字符串转为数组
        // this.form.starting_point_coord = this.form.starting_point_coord.split(',');
        // this.form.destination_coord = this.form.destination_coord.split(',');

      } else {
        this.form = {};
      }
    }
  },
  mounted() {
    //获取服务项目
    this.getFuwuxiangmu();

    // 腾讯地图组件监听
    // window.addEventListener(
    //   'message',
    //   function (event) {
    //     // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
    //     var loc = event.data;
    //     if (loc && loc.module === 'locationPicker') {
    //       // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
    //       window.parent.selectAdderss(loc);
    //     }
    //   },
    //   false,
    // );
    // window.selectAdderss = this.selectAdderss;

    //默认获取单双地址
    this.getdefault();

    this.getread();

  },
  methods: {
    //获取待接单受理信息接口
    getread(){
      delay_joinread(this.data.id).then(res => {
        this.form = res.data;
        this.dirType = res.data.odd_even_address;
        // console.log(res)
      })
    },

    //获取服务项目
    getFuwuxiangmu(){
      console.log(this.form)
      // 弃用
      // getget_services_available().then(res => {
      //   this.ServicesAvailable = res.data;
      // })
      getrescue_type(this.form.channel_id).then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    //选择服务项目获取是单地址还是双地址
    selectServicesAvailable(value){
      let where = {
        items: value
      }
      get_services_config(this.form.channel_id,where).then(res => {
        // console.log(res);
        this.dirType = res.data.dirType;
      })
    },

    //默认获取是单地址还是双地址
    getdefault(){
      let where = {
        items: this.form.rescue_type
      }
      get_services_config(this.form.channel_id,where).then(res => {
        // console.log(res);
        this.dirType = res.data.dirType;
      })
    },

    //提交按钮
    submit(){
      Saveaccept(this.form.id).then(res => {
        if(res.code === 200){
          this.$message.success(res.msg)
          this.$emit('refresh',false);
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },


    save() {
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //     this.loading = true;
      //     this.updateVisible(false);
      //     this.$emit('done');
      //     this.loading = false;
      //   } else {
      //     return false;
      //   }
      // });
      Saveaccept(this.form.id).then(res => {
        if(res.code === 200){
          this.$message.success(res.msg)
          this.$emit('done',false);
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //显示编辑
    openEdit(){
      this.isUpdate = true;
    },

    //点击取消
    quxiao(){
      if(this.isUpdate == true){
        this.isUpdate = false;
      }else {
        this.updateVisible(false);
      }
    },

    //确认修改
    confirm(){
      if(this.isUpdate == true){
        console.log(this.form,'form')
        //判断下是单地址还是双地址
        if(this.dirType == 'single'){
          this.form.starting_point = this.form.destination;
          this.form.starting_point_coord = this.form.destination_coord;
          delete this.form.destination
          delete this.form.destination_coord
        }
        let id = this.form.id
        Editdelay_join(id,this.form).then(res => {
          console.log(res);
          if(res.code === 200){
            this.$message.success(res.msg)
            this.isUpdate = false;
            //调用下详情
            getread(id).then(back => {
              console.log(back)
              this.form = back.data;
            })
          }else {
            this.$message.error(res.msg);
          }
        }).catch(e => {
          this.$message.error(e.msg)
        })
      }else {
        this.updateVisible(false);
      }
    },

    // 现场照片
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    successUpload(file,fileList){
      this.dialogImageUrl.file.data.urls;
      // 上传成功后的列表
      this.form.scene_picture.push(file.data.urls)
    },

    //关闭选择位置
    handleClose(){
      this.showlocation = false;
    },
    //选址组件取消按钮
    cancel(){
      // 隐藏选址弹窗组件
      this.showlocation = false;
      // 显示受理弹窗
      this.updateVisible(true);
    },

    //点击救援起点
    opensiteselection(number){
      // // 显示选址组件
      // this.location = true;
      // // 隐藏受理弹窗
      // // this.updateVisible(false);
      //
      // // 用于判断是起点还是终点
      // this.origin = number;
      // //打开腾讯地图选址组件
      // let keys = settingConfig.Tencentkey;
      // this.keyUrl = `https://apis.map.qq.com/tools/locpicker?type=1&key=${keys}&referer=myapp&coordtype=1&sig=ytLgIfdiuGLdxRAl1MZ7qwxPOI3W5K9k`;
      // 用于判断是起点还是终点
      this.origin = number;
      this.showlocation = true;
      //this.showAMapLoadersiteselection = true;
      this.showAMapLoadersiteselection = false;
      this.$nextTick(() => {
        this.showAMapLoadersiteselection = true;
      })
    },

    // 选择经纬度
    selectAdderss(data) {
      console.log(data)
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.form.starting_point = data.poiaddress + data.poiname;
        // 救援起点的经度纬度
        this.form.starting_point_coord[0] = data.latlng.lat;
        this.form.starting_point_coord[1] = data.latlng.lng;
        // 隐藏选址弹窗组件
        this.showlocation = false;
        // 显示受理弹窗
        this.updateVisible(true);
      }else {
        //救援终点位置
        this.form.destination = data.poiaddress + data.poiname;
        // 救援终点经度纬度
        this.form.destination_coord[0] = data.latlng.lat;
        this.form.destination_coord[1] = data.latlng.lng;
        // 隐藏选址弹窗组件
        this.showlocation = false;
        // 显示受理弹窗
        this.updateVisible(true);
      }
    },

    //获取选择地址后的数据
    getlocation(value){
      console.log(value)
      console.log('获取最新的位置')
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.form.starting_point = value.pname + value.cityname + value.adname + value.address;
        // 救援起点的经度纬度
        this.form.starting_point_coord[0] = value.location.lng;
        this.form.starting_point_coord[1] = value.location.lat;
      }else {
        //救援终点位置
        this.form.destination = value.pname + value.cityname + value.adname + value.address;
        // 救援终点经度纬度
        this.form.destination_coord[0] = value.location.lng;
        this.form.destination_coord[1] = value.location.lat;
      }
      this.showlocation = false;
    },

    // 点击拨打电话
    tel(phone,tel_type){
      console.log('点击拨打电话')
      get_tel_no().then(res => {
        if(res.data.is_exist == 0){

          // 显示拨打电话弹窗
          let data = {
            showPhone: true
          }
          this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
          // 要拨打的手机号
          let dianhua = {
            phone: phone
          }
          this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

          // 获取 订单号id
          let orderid = {
            orderid: this.data.id,
          }
          this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

          // 获取呼出类型
          let teltype = {
            tel_type: tel_type
          }
          this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
        }else {
          this.$message.error('暂无坐席')
        }
      })
    },

    // 电话话术
    openScript(){
      let showScript = true;
      this.$store.dispatch('theme/setshowScript', showScript).then(() => {
        console.log('打开成功')
        console.log(this.$store.state.theme.showScript,'全局话术')
      }).catch((e) => {
        console.error(e)
        this.$message.error('打开失败')
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

.tianjiagenjin{
  width: 670px;
  font-size: 18px;
}
</style>
